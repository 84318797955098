<template>
  <router-view/>

  <ScrollTop />
</template>

<script>
import ScrollTop from 'primevue/scrolltop';

export default {
  components: {
    ScrollTop,
  }
}
</script>

<style>
</style>
