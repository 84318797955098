<template>
   <footer class="site-footer">
      <div class="container">
         <div class="row">
            <div class="col-sm-12 col-md-6">
               <h6>Sobre</h6>
               <p class="text-justify">
                  Somos uma empresa com o intuíto de trazer a melhor solução para o profissional da saúde psicológica,
                  podendo assim sempre focar melhor em seus pacientes assegurando sempre o bem estar do mesmo.
               </p>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
               <h6>Contato</h6>
               <ul class="social-icons mt-3">
                  <li>
                    <a class="facebook" href="https://wa.me/5534991212391?text=Ol%C3%A1%2C+gostaria+de+agendar+uma+demonstra%C3%A7%C3%A3o+do+sistema+Psico+Prontu%C3%A1rios%21" target="_blank">
                        <i class="fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a class="facebook" href="https://www.instagram.com/psicoprontuarios/" target="_blank">
                        <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
               </ul>
            </div>
         </div>
         <hr />
      </div>
      
      <div class="container">
         <div class="row">
            <div class="col-md-12 col-sm-6 col-xs-12">
               <p class="copyright-text">
                  Copyright &copy; 2024 todos os direitos reservados by <span class="fw-bold">Psico Prontuários</span>.
               </p>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.site-footer {
   background-color: #26272b;
   padding: 45px 0 20px;
   font-size: 15px;
   line-height: 24px;
   color: #737373;
}
.site-footer hr {
   border-top-color: #bbb;
   opacity: 0.5;
}
.site-footer hr.small {
   margin: 20px 0;
}
.site-footer h6 {
   color: #fff;
   font-size: 16px;
   text-transform: uppercase;
   margin-top: 5px;
   letter-spacing: 2px;
}
.site-footer a {
   color: #737373;
}
.site-footer a:hover {
   color: #3366cc;
   text-decoration: none;
}
.footer-links {
   padding-left: 0;
   list-style: none;
}
.footer-links li {
   display: block;
}
.footer-links a {
   color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
   color: #3366cc;
   text-decoration: none;
}
.footer-links.inline li {
   display: inline-block;
}

.site-footer .social-icons a {
   width: 40px;
   height: 40px;
   margin-left: 6px;
   margin-right: 0;
   border-radius: 100%;
   background-color: #33353d;
}
.copyright-text {
   margin: 0;
}
@media (max-width: 991px) {
   .site-footer [class^="col-"] {
      margin-bottom: 30px;
   }
}
@media (max-width: 767px) {
   .site-footer {
      padding-bottom: 0;
   }
   .site-footer .copyright-text,
   .site-footer .social-icons {
      text-align: center;
   }
}
.social-icons {
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
}
.social-icons li {
   display: inline-block;
   margin-bottom: 4px;
}
.social-icons li.title {
   margin-right: 15px;
   text-transform: uppercase;
   color: #96a2b2;
   font-weight: 700;
   font-size: 13px;
}
.social-icons a {
   background-color: #eceeef;
   color: #818a91;
   font-size: 16px;
   display: inline-block;
   line-height: 44px;
   width: 44px;
   height: 44px;
   text-align: center;
   margin-right: 8px;
   border-radius: 100%;
   -webkit-transition: all 0.2s linear;
   -o-transition: all 0.2s linear;
   transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
   color: #fff;
   background-color: #29aafe;
}
.social-icons.size-sm a {
   line-height: 34px;
   height: 34px;
   width: 34px;
   font-size: 14px;
}
.social-icons a.facebook:hover {
   background-color: #3b5998;
}
.social-icons a.twitter:hover {
   background-color: #00aced;
}
.social-icons a.linkedin:hover {
   background-color: #007bb6;
}
.social-icons a.dribbble:hover {
   background-color: #ea4c89;
}
@media (max-width: 767px) {
   .social-icons li.title {
      display: block;
      margin-right: 0;
      font-weight: 600;
   }
}
</style>
