<template>
    <div class="container" id="modalidades">
        <div class="row">
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-solid fa-calendar fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            Agenda online
                        </span>
                    </div>

                    <div class="card-body">
                        Agendamento de consultas online, fique sempre por detro de suas consultas marcadas,
                        e receba notificações sobre consultas próximas para nunca se esquecer.
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-solid fa-money-bill-trend-up fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            Controle Financeiro
                        </span>
                    </div>

                    <div class="card-body">
                        Organize suas contas a receber. 
                        Controle todos os pagamentos de seus clientes. 
                        Dashboard com informações sustentáveis para que saiba sempre como está a situação.
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-regular fa-folder-open fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            Controle de Prontuários
                        </span>
                    </div>

                    <div class="card-body">
                        Gerencie o prontuário e anotações de seus clientes de uma forma eficiente e segura. 
                        Personalize os formulários das sessões de acordo com sua abordagem ou serviço.
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-solid fa-file-pdf fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            PDF's Personalizados
                        </span>
                    </div>

                    <div class="card-body">
                        Export os PDF's dos prontuários de seus pacientes de forma rápida e prática, podendo assim sempre ter o controle de seus atendimentos.
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-solid fa-bullhorn fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            Notificações Automáticas
                        </span>
                    </div>

                    <div class="card-body">
                        Reduza em até 30% as faltas dos pacientes. Enviamos notificações de sessões ou compromissos via e-mail de forma automática.
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3" data-aos="zoom-in" data-aos-duration="2000" >
                <div class="card shadow">
                    <div class="card-header d-flex flex-column">
                        <i class="fa-solid fa-mobile-screen fa-2x text-roxo"></i>
                        <span class="text-roxo fs-2 fw-bold">
                            Indicações
                        </span>
                    </div>

                    <div class="card-body">
                        Indiquem colegas da área a usarem nosso sistema com um link de indicação e receba por isso.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>